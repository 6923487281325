import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'

import 'grapesjs/dist/css/grapes.min.css'
import grapesjs from 'grapesjs'
import grapesjsMjml from 'grapesjs-mjml'

import GraphQlMixin from '@/mixins/graphql.mixin'
import { mapMutations } from 'vuex'

import MailTemplateMixin from '@/mixins/mailTemplate.mixin'

const mjml2html = require('mjml-browser')

export default {
  props: ['id', 'isEmbedded', 'variables'],
  components: {
    FullScreenLoader,
    grapesjs,
    grapesjsMjml
  },
  mixins: [GraphQlMixin, MailTemplateMixin],
  data() {
    return {
      editor: null,
      validating: false,
      submitting: false,
      uploading: false,
      whereConditions: [],
      errorMessages: {
        name: []
      },
      mailTemplate: {
        from: 'citd-asset-notification@mail.rakuten.com',
        name: null,
        description: null,
        editorData: null
      },
      fromAddresses: ['citd-asset-notification@mail.rakuten.com', 'rams-notifications@mail.rakuten.com']
    }
  },
  watch: {
    id() {
      this.initializeEditor()
    },
    mailTemplate() {
      if (this.isEmbedded) {
        this.updateNotification()
      }
    },
    'mailTemplate.to': function() {
      if (this.isEmbedded) {
        this.updateNotification()
      }
    },
    'mailTemplate.cc': function() {
      if (this.isEmbedded) {
        this.updateNotification()
      }
    },
    'mailTemplate.bcc': function() {
      if (this.isEmbedded) {
        this.updateNotification()
      }
    },
    'mailTemplate.subject': function() {
      if (this.isEmbedded) {
        this.updateNotification()
      }
    }
  },
  computed: {
    loading() {
      return this.$apollo.queries.mailTemplate.loading
    },
    loaderMessage() {
      return this.loading ? 'Loading mail template' : this.submitting ? 'Submitting' : this.uploading ? 'Uploading image...' : ''
    },
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp
      }
    },
    breadcrumbItems() {
      return [
        {
          text: 'Mail Templates',
          disabled: false,
          to: { name: 'list-mail-templates' },
          exact: true
        },
        { text: `Edit ${this.id}` }
      ]
    }
  },
  methods: {
    required(v) {
      if (v instanceof Array && v.length === 0) {
        return 'Required'
      }
      return !!v || 'Required'
    },
    change() {
      this.$emit('change', this.editor.getHtml())
    },
    updateNotification() {
      let editorDataObj = JSON.parse(this.mailTemplate.editorData)
      this.$emit('getNotification', {
        body: this.convertMjmlToHtml(editorDataObj['editor-html']),
        subject: this.mailTemplate.subject,
        to: this.mailTemplate.to,
        cc: this.mailTemplate.cc,
        bcc: this.mailTemplate.bcc,
        from: this.mailTemplate.from
      })
    },
    convertMjmlToHtml(editorMjml) {
      return mjml2html(editorMjml).html
    },
    initializeEditor() {
      this.editor = grapesjs.init({
        container: '#editor',
        fromElement: false,
        storageManager: {
          id: 'editor-',
          type: 'local',
          autoload: false,
          autosave: true,
          storeHtml: true
        },
        styleManager: {},
        assetManager: {
          assets: []
          // Temporarily disable file upload
          // uploadFile: (e) => {
          //   let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
          //   this.uploading = true
          //   const formData = new FormData()
          //   let bigFiles = []

          //   for (let f of files) {
          //     if (f.size < 10485760) {
          //       formData.append('file', f)
          //     } else {
          //       bigFiles.push(f.name)
          //     }
          //   }
  
          //   document.getElementById('gjs-am-title').innerHTML = '<div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-circular v-progress-circular--indeterminate" style="height: 32px; width: 32px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715" style="transform: rotate(0deg);"><circle fill="transparent" cx="45.714285714285715" cy="45.714285714285715" r="20" stroke-width="5.714285714285714" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"></circle></svg><div class="v-progress-circular__info"></div></div>'
  
          //   return ApiService.customRequest({
          //     method: 'post',
          //     url: '/upload',
          //     headers: { 'Content-Type': 'multipart/form-data' },
          //     data: formData
          //   })
          //   .then(response => {
          //     if (response.status >= 200 && response.status < 300 && !_.isEmpty(response.data.urls)) {
          //       return response
          //     }
          //   })
          //   .then(response => {
          //     response.data.urls.forEach(i => {
          //       this.editor.AssetManager.add(i)
          //     })
          //   })
          //   .catch(error => {
          //     this.setAppSnackbar({
          //       text: 'Failed to upload image',
          //       color: 'red'
          //     })
          //   })
          //   .finally(() => {
          //     this.uploading = false
          //     document.getElementById('gjs-am-title').innerHTML = 'Drop files here or click to upload'
          //     if (bigFiles.length > 0) {
          //       this.setAppSnackbar({
          //         text: `Cannot upload ${bigFiles.toString()}. File(s) exceed(s) 10MB.`,
          //         color: 'red'
          //       })
          //     }
          //   })
          // },
          // multiUpload: true
        },
        plugins: [
          grapesjsMjml
        ],
        pluginsOpts: {
          [grapesjsMjml]: {
          }
        }
      })
  
      this.editor.BlockManager.get('mj-1-column').set({
        content: '<mj-section><mj-group><mj-column><mj-text>Column 1</mj-text></mj-column></mj-group></mj-section>'
      })
  
      this.editor.BlockManager.get('mj-2-columns').set({
        content: '<mj-section><mj-group><mj-column><mj-text>Column 1</mj-text></mj-column><mj-column><mj-text>Column 2</mj-text></mj-column></mj-group></mj-section>'
      })
  
      this.editor.BlockManager.get('mj-3-columns').set({
        content: '<mj-section><mj-group><mj-column><mj-text>Column 1</mj-text></mj-column><mj-column><mj-text>Column 2</mj-text></mj-column><mj-column><mj-text>Column 3</mj-text></mj-column></mj-group></mj-section>'
      })
  
      this.editor.on('block:drag:stop', (model, obj) => {
        this.editor.select(model)
        if (obj.id === 'mj-image') {
          this.editor.runCommand('open-assets', {
            target: this.editor.getSelected()
          })
        }
      })

      this.editor.on('change:changesCount', e => {
        if (this.isEmbedded) {
          let editorData = {}
          Object.entries(localStorage).forEach(([key, value]) => {
            if (key.indexOf('editor-') === 0 || key.indexOf('gjs') === 0) {
              editorData[key] = value
            }
          })
          this.mailTemplate.editorData = JSON.stringify(editorData)
          this.updateNotification()
        }
      })
    },
    replaceVariable(oldValue) {
      let newValue = null
      if (oldValue !== null && this.variables !== undefined) {
        if (typeof(oldValue) === 'object') {
          newValue = []
          oldValue.forEach((el) => {
            let variable = el.match('(?<={{).*?(?=}})')
            if (variable !== null && Object.keys(this.variables).includes(variable[0])) {
              if (el !== '{{NotificationAdEmails}}') {
                el = el.replace(`{{${variable}}}`, this.variables[variable[0]])
              } else {
                if ((this.variables[variable[0]] && this.variables[variable[0]].length > 0) && this.variables[variable[0]].toString().split(',').length > 0) {
                  this.variables[variable[0]].toString().split(',').forEach((i) => {
                    newValue.push(i)
                  })
                }
              }
            }
            if (el !== '{{NotificationAdEmails}}') {
              newValue.push(el)
            }
          })
          return newValue
        } else {
          let variables = oldValue.match(/(?<={{).*?(?=}})/g)
          if (variables !== null) {
            variables.forEach((el) => {
              oldValue = oldValue.replace(`{{${el}}}`, this.variables[el])
            })
          }
          return oldValue
        }
      } else {
        return oldValue
      }
    },
    async validateField(value, path, additionalRules = []) {
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.id) {
        this.whereConditions.push({ path: 'id', comparison: 'equal', value: this.id, negate: true })
      }
      // check other conditions if they have values
      for (let rule of additionalRules) {
        if (rule.value) {
          this.whereConditions.push(rule)
        } else {
          this.errorMessages[path].push(rule.path + ' not selected')
        }
      }

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
            query mailTemplates($where: [WhereExpression]) {
              mailTemplates(where: $where) {
                totalCount
              }
            }
          `,
          variables: { where: this.whereConditions }
        })
        if (data && data.mailTemplates) {
          const mailTemplates = data.mailTemplates
          const msg = `Mail template with the same ${this.whereConditions[0].path} exists`
          if (mailTemplates.totalCount > 0) {
            this.errorMessages[this.whereConditions[0].path].push(msg)
          } else {
            this.errorMessages[this.whereConditions[0].path] = _.remove(
              this.errorMessages[this.whereConditions[0].path],
              msg
            )
          }
        } else if (error) {
          this.graphQLOnError(`Failed to validate mail template data with server. ${error.toString()}`)
        } else {
          this.graphQLOnError('Failed to connect to server.')
        }
      }
    },
    async validateName() {
      await this.validateField(this.mailTemplate.name, 'name')
    },
    async validate() {
      this.validating = true

      // reset validation
      this.$refs.form.resetValidation()
      this.errorMessages = {
        name: []
      }

      // validate
      await this.validateName()
      const isValid = this.$refs.form.validate() && _.every(this.errorMessages, o => o.length === 0)
      this.validating = false
      if (isValid) {
        this.submit()
      }
    },
    setDefaultEditorComponents() {
      this.editor.addComponents(
        `<mjml>
          <mj-body>
            <mj-section>
              <mj-group>
                <mj-column>
                  <mj-text>Double click to edit Header text</mj-text>
                </mj-column>
              </mj-group>
            </mj-section>
            <mj-section>
              <mj-group>
                <mj-column>
                  <mj-text>Column 1</mj-text>
                </mj-column>
                <mj-column>
                  <mj-text>Column 2</mj-text>
                </mj-column>
              </mj-group>
            </mj-section>
            <mj-section>
              <mj-group>
                <mj-column>
                  <mj-text>Double click to edit Footer text</mj-text>
                </mj-column>
              </mj-group>
            </mj-section>
          </mj-body>
        </mjml>`
      )
    },
    submit() {
      // remove errors
      this.removeErrorByType('form')

      // trigger save
      this.editor.store()
      
      let editorData = {}
      Object.entries(localStorage).forEach(([key, value]) => {
        if (key.indexOf('editor-') === 0 || key.indexOf('gjs') === 0) {
          editorData[key] = value
        }
      })

      const body = this.convertMjmlToHtml(editorData['editor-html'])

      const input = {
        mailTemplateType: this.mailTemplate.mailTemplateType,
        mailTemplateSubType: this.mailTemplate.mailTemplateSubType,
        name: this.mailTemplate.name,
        description: this.mailTemplate.description,
        editorData: JSON.stringify(editorData),
        from: this.mailTemplate.from,
        to: this.mailTemplate.to,
        cc: this.mailTemplate.cc,
        bcc: this.mailTemplate.bcc,
        subject: this.mailTemplate.subject,
        body
      }
      
      const fields = this.getFieldsGql('read', 'MailTemplate', [
        'id',
        'mailTemplateType',
        'mailTemplateSubType',
        'name',
        'description',
        'subject'
      ])
      // send by form type
      this.submitting = true
      if (this.id) {
        input.id = this.id
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation updateMailTemplate($input: UpdateMailTemplateInput!) {
                updateMailTemplate(input: $input) {
                  mailTemplate {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const mailTemplate = _.get(data, 'updateMailTemplate.mailTemplate')
            this.setAppSnackbar({
              text: `Updated mail template #${mailTemplate.id} ${mailTemplate.name} successfully.`,
              color: 'success'
            })
            this.submitting = false
            this.$router.push({ name: 'list-mail-templates', params: { updatedItem: mailTemplate } })
          })
          .catch(e => {
            this.addError(null, `Failed to update mail template. ${e.toString()}`, 'form')
            this.submitting = false
          })
      } else {
        this.$apollo
          .mutate({
            // Query
            mutation: this.$gql`
              mutation createMailTemplate($input: CreateMailTemplateInput!) {
                createMailTemplate(input: $input) {
                  mailTemplate {
                    ${fields}
                  }
                }
              }
            `,
            variables: { input }
          })
          .then(({ data }) => {
            const mailTemplate = _.get(data, 'createMailTemplate.mailTemplate')
            if (mailTemplate && mailTemplate.id && mailTemplate.name) {
              this.setAppSnackbar({
                text: `Created mail template #${mailTemplate.id} ${mailTemplate.name} successfully.`,
                color: 'success'
              })
              this.submitting = false
              this.$router.push({ name: 'list-mail-templates', params: { updatedItem: mailTemplate } })
            } else {
              this.addError(null, 'Failed to create mail template.', 'form')
              this.submitting = false
            }
          })
          .catch(e => {
            this.addError(null, `Failed to create mail template. ${e.toString()}`, 'form')
            this.submitting = false
          })
      }
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    mailTemplate: {
      query() {
        const fields = this.getFieldsGql('read', 'MailTemplate', [
          'id',
          'mailTemplateType',
          'mailTemplateSubType',
          'name',
          'description',
          'editorData',
          'from',
          'to',
          'cc',
          'bcc',
          'subject'
        ])
        return this.$gql`
          query GetMailTemplate($id: ID!) {
            mailTemplate(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        return !this.id
      },
      update: () => null,
      result({ data, error }) {
        const mailTemplate = data.mailTemplate
        const from = mailTemplate && this.fromAddresses.includes(mailTemplate.from) ? mailTemplate.from : null
        this.mailTemplate = {
          mailTemplateType: mailTemplate ? mailTemplate.mailTemplateType : null,
          mailTemplateSubType: mailTemplate ? mailTemplate.mailTemplateSubType : null,
          name: mailTemplate ? mailTemplate.name : null,
          description: mailTemplate ? mailTemplate.description : null,
          editorData: mailTemplate ? this.replaceVariable(mailTemplate.editorData) : null,
          from,
          to: mailTemplate ? this.replaceVariable(mailTemplate.to) : null,
          cc: mailTemplate ? this.replaceVariable(mailTemplate.cc) : null,
          bcc: mailTemplate ? mailTemplate.bcc : null,
          subject: mailTemplate ? this.replaceVariable(mailTemplate.subject) : null
        }

        if (this.mailTemplate.editorData === null) {
          this.setDefaultEditorComponents()
        } else {
          const editor = JSON.parse(this.mailTemplate.editorData)
          
          if (editor['editor-components'].length > 0) {
            this.editor.addComponents(JSON.parse(editor['editor-components']))
          }
          
          if (editor['editor-assets'].length > 0) {
            this.editor.AssetManager.add(JSON.parse(editor['editor-assets']))
          }
          if (editor['editor-styles'].length > 0) {
            this.editor.SelectorManager.add(JSON.parse(editor['editor-styles']))
          }
        }
        if (error) {
          this.graphQLOnError(`Failed to get mail template data from server. ${error.toString()}`)
          this.mailTemplate = {}
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        } else {
          this.$emit('name', this.mailTemplate.name)
        }
      }
    }
  },
  mounted() {
    this.initializeEditor()

    if (!this.id) {
      this.setDefaultEditorComponents()
    }
  }
}
