import GraphQlMixin from '@/mixins/graphql.mixin'
import FormattingMixin from '@/mixins/formatting.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [GraphQlMixin, FormattingMixin],
  data() {
    return {
      mailTemplateTypes: [
        {
          value: 'WORKFLOW_MAIL_TEMPLATE',
          text: 'Workflow'
        }
      ],
      mailTemplateSubTypes: [
        {
          value: 'DESKTOP_DEPLOYMENT',
          text: 'Desktop Deployment'
        },
        {
          value: 'LAPTOP_DEPLOYMENT',
          text: 'Laptop Deployment'
        },
        {
          value: 'PC_ACCESSORY',
          text: 'Pc Accessory'
        },
        {
          value: 'PC_OS_REFRESH',
          text: 'Pc Os Refresh'
        },
        {
          value: 'PC_REPAIR',
          text: 'Pc Repair'
        },
        {
          value: 'PC_RETURN',
          text: 'Pc Return'
        },
        {
          value: 'PC_USER_CHANGE',
          text: 'Pc User Change'
        },
        {
          value: 'MOBILE_DEPLOYMENT',
          text: 'Mobile Deployment'
        },
        {
          value: 'MOBILE_RETURN',
          text: 'Mobile Return'
        },
        {
          value: 'MOBILE_SIM_CHANGE',
          text: 'Mobile Sim Change'
        },
        {
          value: 'MOBILE_USER_CHANGE',
          text: 'Mobile User Change'
        },
        {
          value: 'UNKNOWN',
          text: 'Unknown'
        }
      ]
    }
  }
}